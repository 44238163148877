.btn-filter {
	padding: 0 5px;

	.btn.btn-light.dropdown-toggle {
		width: 100%;
		background-color: $white;
		border: 1px solid #e4e6fc;
		box-shadow: none;
	}
}

.form-group {
	@include transition(.5s ease all);

	.form-control {

		&:focus,
		&:active {
			border: 1px solid $primary;
			color: $primary;
		}
	}

	label {
		color: $gray;
		font-size: 12px;
	}
}

.nav-pills {
	.nav-item {
		margin: 0 5px;

		.nav-link {
			color: $primary;
			background-color: $white;

			&.active {
				background-color: #e3eaef;
				color: $primary;
				box-shadow: 0 4px 8px rgb(0 0 0 / 3%);
			}
		}
	}
}

.pagination {
	.page-item {
		&.active {
			.page-link {
				border-color: $primary;
				background-color: $primary;
			}
		}
	}
}

.wizard-form {
	.bxShadow {
		box-shadow: none !important;
	}

	.btn-add-vehicle {
		margin: 81px 0 0 0;
	}

	.empty-vehicle {
		display: inline-block;
		width: 100%;
		text-align: center;
	}

	#msform {
		position: relative;
		margin-top: 40px
	}

	#msform fieldset:not(:first-of-type) {
		display: none
	}

	#msform .btn-next {
		@include transition(.5s ease all);
		width: 100px;
		background: $primary;
		font-weight: bold;
		color: white;
		border: 0 none;
		cursor: pointer;
		padding: 10px 5px;
		margin: 0 20px 0 0;
		border-radius: 10px;
		float: right;
		right: 20px;
		text-align: center;
		display: block;

		&.btn-enviar {
			width: 200px;
		}
	}

	#msform .btn-next:hover,
	#msform .btn-next:focus {
		box-shadow: 0 0 0 2px white, 0 0 0 3px $primaryHover;
	}

	#msform .btn-previous {
		@include transition(.5s ease all);
		width: 100px;
		background: #616161;
		font-weight: bold;
		color: white;
		border: 0 none;
		cursor: pointer;
		padding: 10px 5px;
		margin: 0 0 0 20px;
		border-radius: 10px;
		float: left;
		right: 20px;
		text-align: center;
	}

	#msform .btn-previous:hover,
	#msform .btn-previous:focus {
		box-shadow: 0 0 0 2px white, 0 0 0 3px #616161
	}

	select.list-dt {
		border: none;
		outline: 0;
		border-bottom: 1px solid #ccc;
		padding: 2px 5px 3px 5px;
		margin: 2px
	}

	select.list-dt:focus {
		border-bottom: 2px solid $primary;
	}

	.subtitle {
		margin: 10px 0 20px 0;

		h5 {
			text-align: center;
		}
	}

	.card {
		z-index: 0;
		border: none;
		border-radius: 0.5rem;
		position: relative
	}

	.fs-title {
		font-size: 25px;
		color: #2C3E50;
		margin-bottom: 10px;
		font-weight: bold;
		text-align: left
	}

	#progressbar {
		margin-bottom: 30px;
		overflow: hidden;
		color: lightgrey;
		padding: 0;
	}

	#progressbar .active {
		color: #000000
	}	

	#progressbar li {
		list-style-type: none;
		font-size: 12px;
		// width: 25%;
		width: 33.33%;
		float: left;
		position: relative
	}

	#progressbar #geral:before {
		font-family: "Font Awesome 5 Free";
		content: "\f328"
	}

	#progressbar #financeiro:before {
		font-family: "Font Awesome 5 Free";
		content: "\f09d"
	}

	#progressbar #agente:before {
		font-family: "Font Awesome 5 Free";
		content: "\f1ad"
	}

	#progressbar #comprador:before {
		font-family: "Font Awesome 5 Free";
		content: "\f007"
	}

	#progressbar #arqAnexo:before {
		font-family: "Font Awesome 5 Free";
		content: "\f0c6"
	}


	#progressbar li:before {
		width: 50px;
		height: 50px;
		line-height: 45px;
		display: block;
		font-size: 18px;
		color: #ffffff;
		background: lightgray;
		border-radius: 50%;
		margin: 0 auto;
		padding: 2px
	}

	#progressbar li:after {
		content: '';
		width: 100%;
		height: 2px;
		background: lightgray;
		position: absolute;
		left: 0;
		top: 25px;
		z-index: -1
	}

	#progressbar li strong {
		font-size: 14px;
	}

	#progressbar li.active:before,
	#progressbar li.active:after {
		background: $primary
	}

	// Progressbar RJ

	#progressbarRJ {
		margin-bottom: 30px;
		overflow: hidden;
		color: lightgrey;
		padding: 0;
	}

	#progressbarRJ .active {
		color: #000000
	}	

	#progressbarRJ li {
		list-style-type: none;
		font-size: 12px;
		width: 100%;		
		float: left;
		position: relative
	}

	#progressbarRJ #geral:before {
		font-family: "Font Awesome 5 Free";
		content: "\f328"
	}

	#progressbarRJ #financeiro:before {
		font-family: "Font Awesome 5 Free";
		content: "\f09d"
	}

	#progressbarRJ #agente:before {
		font-family: "Font Awesome 5 Free";
		content: "\f1ad"
	}

	#progressbarRJ #comprador:before {
		font-family: "Font Awesome 5 Free";
		content: "\f007"
	}

	#progressbarRJ #arqAnexo:before {
		font-family: "Font Awesome 5 Free";
		content: "\f302"
	}


	#progressbarRJ li:before {
		width: 50px;
		height: 50px;
		line-height: 45px;
		display: block;
		font-size: 18px;
		color: #ffffff;
		background: lightgray;
		border-radius: 50%;
		margin: 0 auto;
		padding: 2px
	}

	#progressbarRJ li:after {
		content: '';
		width: 100%;
		height: 2px;
		background: lightgray;
		position: absolute;
		left: 0;
		top: 25px;
		z-index: -1
	}

	#progressbarRJ li strong {
		font-size: 14px;
	}

	#progressbarRJ li.active:before,
	#progressbarRJ li.active:after {
		background: $primary
	}

	// FIM

	// Progressbar RJ - Visualizar

	#progressbarVisualizarRJ {
		margin-bottom: 30px;
		overflow: hidden;
		color: lightgrey;
		padding: 0;
	}

	#progressbarVisualizarRJ .active {
		color: #000000
	}	

	#progressbarVisualizarRJ li {
		list-style-type: none;
		font-size: 12px;
		width: 20%;		
		float: left;
		position: relative
	}

	#progressbarVisualizarRJ #geral:before {
		font-family: "Font Awesome 5 Free";
		content: "\f328"
	}

	#progressbarVisualizarRJ #financeiro:before {
		font-family: "Font Awesome 5 Free";
		content: "\f09d"
	}

	#progressbarVisualizarRJ #agente:before {
		font-family: "Font Awesome 5 Free";
		content: "\f1ad"
	}

	#progressbarVisualizarRJ #comprador:before {
		font-family: "Font Awesome 5 Free";
		content: "\f007"
	}

	#progressbarVisualizarRJ #arqAnexo:before {
		font-family: "Font Awesome 5 Free";
		content: "\f15b"
	}


	#progressbarVisualizarRJ li:before {
		width: 50px;
		height: 50px;
		line-height: 45px;
		display: block;
		font-size: 18px;
		color: #ffffff;
		background: lightgray;
		border-radius: 50%;
		margin: 0 auto;
		padding: 2px
	}

	#progressbarVisualizarRJ li:after {
		content: '';
		width: 100%;
		height: 2px;
		background: lightgray;
		position: absolute;
		left: 0;
		top: 25px;
		z-index: -1
	}

	#progressbarVisualizarRJ li strong {
		font-size: 14px;
	}

	#progressbarVisualizarRJ li.active:before,
	#progressbarVisualizarRJ li.active:after {
		background: $primary
	}

	// FIM
	

	// Inicio progressbar - Show.vue

	#progressbarview {
		margin-bottom: 30px;
		overflow: hidden;
		color: lightgrey;
		padding: 0;
	}

	#progressbarview .active {
		color: #000000
	}

	#progressbarview li {
		list-style-type: none;
		font-size: 12px;
		width: 25%;
		float: left;
		position: relative
	}


	#progressbarview #geral:before {
		font-family: "Font Awesome 5 Free";
		content: "\f328"
	}

	#progressbarview #financeiro:before {
		font-family: "Font Awesome 5 Free";
		content: "\f09d"
	}

	#progressbarview #agente:before {
		font-family: "Font Awesome 5 Free";
		content: "\f1ad"
	}

	#progressbarview #comprador:before {
		font-family: "Font Awesome 5 Free";
		content: "\f007"
	}

	#progressbarview li:before {
		width: 50px;
		height: 50px;
		line-height: 45px;
		display: block;
		font-size: 18px;
		color: #ffffff;
		background: lightgray;
		border-radius: 50%;
		margin: 0 auto;
		padding: 2px
	}

	#progressbarview li:after {
		content: '';
		width: 100%;
		height: 2px;
		background: lightgray;
		position: absolute;
		left: 0;
		top: 25px;
		z-index: -1
	}

	#progressbarview li strong {
		font-size: 14px;
	}

	#progressbarview li.active:before,
	#progressbarview li.active:after {
		background: $primary
	}

	// Fim

	.container-arquivos{		
		display: flex;
		flex-wrap: wrap; 
		margin-bottom: 50px;
	}

	.card-arquivos-anexo {
		background-color: #fcfcfc;
		width: 20rem;
		height: 6rem;
		border-radius: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: 20px;
		margin-top: 20px;
		padding: 1rem;
		-webkit-box-shadow: 5px 6px 15px 5px #8A8A8A;
		box-shadow: 5px 6px 15px 5px #dedede;

		.icon-clear-arq {
			cursor: pointer;
		}
	  }

	  .file-info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;		
		padding-right: 10px;
	}
	
	.file-info p {
		margin: 0;
	}

	  .card-arquivos-anexo p {
		margin: 0; /* Remove a margem padrão do <p> */
							
	  }	 

	  .file-name {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 230px; /* Ajuste o valor conforme necessário */
		cursor: pointer; /* Mude o cursor para indicar que é interativo */		
		color: #616161;
		padding-top: 5px;	
		
	  }

	  .file-last-modified{
		font-size: 12px;
		color: #ec2434;
	  }

	  .icon-clear-arq{
		margin-left: 15px;
		color: #ec2434;
	  }

	  .icon-acoes-edit{
		margin-left: 5px;
		color: #ec2434;
	  }

	  .acoes-arquivo{		
		display: flex;
		display: inline-flex;

		a {
			background-color: transparent !important;
		}
	  }	  

	  .import-arquivos {
		background-color: #ec2434;
		border-radius: 5px;
		cursor: pointer;
		margin-top: 10px;
		margin-bottom: 15px;
		width: 220px;
		height: 40px;
		justify-content: center;
		padding-top: 10px;
		text-align: center;
		align-items: center;
	}

	.span-text-tamanho {
		font-size: medium;		
		font-weight: 400;
	}
	  

	.form-distance {
		padding-top: 4px;
		padding-left: 24px;
		padding-right: 24px;
		padding-bottom: 0px;

	}

	.radio-group {
		position: relative;
		margin-bottom: 25px
	}

	.radio {
		display: inline-block;
		width: 204;
		height: 104;
		border-radius: 0;
		background: $primary;
		box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
		box-sizing: border-box;
		cursor: pointer;
		margin: 8px 2px
	}



	.radio:hover {
		box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3)
	}

	.radio.selected {
		box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1)
	}

	.fit-image {
		width: 100%;
		object-fit: cover
	}
}

.date-custom {
	input[type="text"]::placeholder {
		text-align: center;
		color: #191d21;
	}

	input[type="text"] {
		text-align: center;
		align-content: center;
		font-size: 12px !important;
		border: 1px solid #e4e6fc;
		border-radius: 30px;
		color: #191d21 !important;
		width: 100%;
		height: 30px;
	}

	border-color: $primary !important;
}

.tooltip-inner {
	max-width: 200px;
	padding: 3px 8px;
	color: #fff;
	text-align: center;
	background-color: $primary !important;
	border-radius: .25rem;
}

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
	margin-left: -3px;
	content: "";
	border-width: 5px 5px 0;
	border-top-color: $primary !important;
}

.today.active.start-date.active.end-date.available {
	background-color: $primary !important;
}

.active.start-date.active.end-date.available {
	background-color: $primary !important;
}

.form-control:focus,
.form-control:active {
	border: 1px solid $primary;
	color: #ec2434;
}

.vdp-datepicker__calendar {
	.cell.selected {
		background: $primary !important;
		color: white !important;
	}
}

.vdp-datepicker.btn-filter {
	input {
		.active {
			border: 1px solid $primary !important;
		}
	}
}

.vdp-datepicker__calendar {
	.cell:not(.blank):not(.disabled).day:hover {
		border: 1px solid $primary !important;
	}
}

.vdp-datepicker__calendar {
	.cell:not(.blank):not(.disabled).month:hover {
		border: 1px solid $primary !important;
	}
}

.vdp-datepicker__calendar {
	.cell:not(.blank):not(.disabled).year:hover {
		border: 1px solid $primary !important;
	}
}

.vdp-datepicker__calendar {
	header {
		.prev:after {
			border-right: 10px solid $primary !important;
		}
	}
}

.vdp-datepicker__calendar {
	header {
		.prev.disabled:after {
			border-right: 10px solid #ddd !important;
		}
	}
}

.vdp-datepicker__calendar {
	header {
		.next:after {
			border-left: 10px solid $primary !important;
			margin-left: 5px;
		}
	}
}

.vdp-datepicker * {
	outline: none;
}

.vs--searchable .vs__dropdown-toggle {
	height: 42px !important;
	background-color: #fdfdff !important;
	border-color: #e4e6fc !important;
}

.vs__selected {
	margin: 10px 2px 0 !important;
}

.vs--searchable .vsdropdown-toggle {
	height: 42px !important;
	background-color: #fdfdff !important;
	border-color: #e4e6fc;
}

.vsselected {
	margin: 10px 2px 0 !important;
}

.vs--searchable.is-invalid {
	.vs__dropdown-toggle {
		border-color: #dc3545 !important;
	}
}

.form-group-contract {
	@include transition(.5s ease all);
	padding-top: 20px;

	.import-contract {
		background-color: #ec2434;
		border-radius: 5px;
		cursor: pointer;
		margin-top: 10px;
		margin-bottom: 15px;
		width: 220px;
		height: 36px;
		justify-content: center;
		padding-top: 10px;
		text-align: center;
		align-items: center;

	}

	.custom-label {
		color: #fff;
	}

	.span-name-arq {
		margin-left: 20px;
		font-weight: bold;
	}

	.span-text {
		font-size: large;
		padding-bottom: 10px;
		font-weight: 400;
	}

	a {
		background-color: #ec3444;
		border-radius: 5px;
		cursor: pointer;
		padding: 10px 20px;
		margin-top: 10px;
		margin-bottom: 10px;
		width: 200px;
		height: 36px;
		justify-content: center;
	}
	.check_box_div{
		padding-top: 10px;
		justify-content: center;	
	}
	
	.text_checkbox{
		margin-bottom: 15px;
		margin-top: 15px;
		font-size: larger;
		font-weight: bolder;
	
	}
	.check-box{
		padding: 0;
		margin:0;
		vertical-align: center;
		position: relative;
		top: 1px;
		overflow: hidden;
		
	}
	
	.label_check_box{
		display: inline-block;
		font-size: 15px;
	}	

}
.btn_exec_faturamento {
	transition: 0.5s ease all;
	width: 100px;
	background: #ec2434;
	font-weight: bold;
	color: #fff !important; 
	border: 0 none;
	cursor: pointer;
	padding: 10px 5px;
	margin: 0 20px 0 0;
	border-radius: 10px;
	float: right;
	right: 20px;
	text-align: center;	
	width: 200px;
  }

  .btn_exec_faturamento:hover,
  .btn_exec_faturamento:focus {
	box-shadow: 0 0 0 2px white, 0 0 0 3px #ec3444;
  }

.form_bt_exe{
	padding-top: 100px;
}